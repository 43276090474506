import React from 'react'
import { Box, Text, Heading, Stack, Button, HStack} from '@chakra-ui/react'
import RichText from "../PrismicRichText";

const ComplaintClaimList = ({ data }) => {
  return (
    <Stack direction={{base:"column-reverse", md:"row"}} spacing="2rem">
      <Box
        maxW="48.25rem"
        borderRadius="16px"
        border="1px"
        borderColor="border.100"
        pr="1.625rem"
        pl={{base:"4",md:"3.5rem"}}
        pb="2.5rem"
      >
        <Text
          fontSize="sm"
          textAlign="right"
          p="1.625rem"
          mb="3.375rem">
          {data.companyName} <br />
          {data.executiveDirector}
          <br />
          {data.applicant}
          <br />
          {data.email}
          <br />
          {data.phone}
          <br />
        </Text>
        <Heading as="h2" fontSize="xl" textAlign="center" mb="3.375rem">
          {data.complaintDemand}
          <br />
          {data.compensation}
        </Heading>
        <Box fontSize="sm" color="black.200" mb="4.75rem">
          <RichText text={data.description}/> <br />
        </Box>
        <Text textAlign="right" fontSize="sm" lineHeight="28px" color="#000000">
          {data.respect} <br />
          {data.nameSurname}
          <br />
          {data.signature}
          <br />
          {data.date}
        </Text>
      </Box>
      <Box maxW={{base:"full",md:"23.25rem"}}>
        <Box fontSize="sm" mb="1rem">
          <RichText text={data.description_5}/>
        </Box>
        <HStack w="full" spacing="6" >
        <Button
          variant="green"
          w={{base:"full",md:"178px"}}
          >
          {data.send}
        </Button>
        <Button
          variant="transparent"
          w={{base:"full",md:"178px"}}
          > 
          {data.keep}
        </Button>
        </HStack>
      </Box>
    </Stack>
  )
}

export default ComplaintClaimList
