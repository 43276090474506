import * as React from 'react'
import { Container, Heading } from '@chakra-ui/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Seo from '../../components/SEO'
import Main from '../../components/layouts/Main'
import SmallNav from '../../components/smallNav'
import ComplaintClaimList from '../../components/viewComplaintClaim/ComplaintClaimList'
import usePrismicViewComplaintClaim from "../../hooks/usePrismicViewComplaintClaim";


const ViewComplaintClaim = () => {
  const { language } = useTranslation()
  const data = usePrismicViewComplaintClaim()
  return(
    <Main>
      <Container
        maxW="7xl"
        mb="9.5rem">
        <Seo
          title={data.title}
          description={data.title}
          link={`https://www.fsm.am/${language}/Դիտել Բողոք-Պահանջը`}
        />
        <SmallNav>
          {data.header}
        </SmallNav>
        <Heading
          as="h1"
          fontSize={{base:"2xl", sm:"3xl"}}
          fontWeight="900"
          mt="5"
          mb="3.375rem">
          {data.title}
        </Heading>
        <ComplaintClaimList data={data} />
      </Container>
    </Main>
  )
}

export default ViewComplaintClaim 

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`