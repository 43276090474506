import { graphql, useStaticQuery } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next'

const usePrismicViewComplaintClaim = () => {
    const { language } = useI18next()
    const newLang = language === 'en' ? 'en-us' : language === 'ru' ? 'ru' : 'hy-am'

    const response = useStaticQuery(graphql`
    query usePrismicViewComplaintClaim {
       allPrismicViewcomplaintclaim(filter: {tags: {in: "view_complaint_claim"}}) {
        nodes {
          lang
          data {
            applicant
            companyname
            compensation
            complaintdemand
            date
            email
            executivedirector
            header
            keep
            namesurname
            phone
            respect
            send
            signature
            title {
              text
            }
            description {
              richText
            }
            description_1 {
              richText
            }
            description_2 {
              richText
            }
            description_3 {
              richText
            }
            description_4 {
              richText
            }
            description_5 {
              richText
            }
          }
        }
      }
    }
  `);
    return sanitize(response.allPrismicViewcomplaintclaim.nodes.filter(item => item.lang === newLang)[0].data);
};

const sanitize = (response) => {
    return {
        header: response.header,
        applicant: response.applicant,
        companyName: response.companyname,
        compensation: response.compensation,
        complaintDemand: response.complaintdemand,
        email: response.email,
        date: response.date,
        executiveDirector: response.executivedirector,
        keep: response.keep,
        nameSurname: response.namesurname,
        phone: response.phone,
        respect: response.respect,
        send: response.send,
        signature: response.signature,
        title: response.title.text,
        description: response.description.richText,
        description_1: response.description_1.richText,
        description_2: response.description_2.richText,
        description_3: response.description_3.richText,
        description_4: response.description_4.richText,
        description_5: response.description_5.richText,
    }
}

export default usePrismicViewComplaintClaim;